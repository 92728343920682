import React from 'react'
import './Animation.css';
export default function Animation () {

  return (

 
    <div className='Animation'>
 
        <ul className="circles">
    
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  
  
</ul>
    </div>
  )
}
